import React, { useEffect } from 'react';
import './Content.css';
import ContentHeader from '../ContentHeaderComponent/ContentHeader';
import SubscriberProfile from '../SubscriberProfile/SubscriberProfile';
import SubscriberOrders from '../SubscriberOders/SubscriberOrders';
import DefaultView from '../DefaultViewComponent/DefaultView';
import TransactionHistory from '../TransactionHistory/TransactionHistory';
import MyWishList from '../MyWishList/MyWishList';
import ManageSubscription from '../ManageSubscription/ManageSubscription';
import UsageHistory from '../UsageHistory/UsageHistory';
import BorrowAirtimeData from '../BorrowAirtimeData/BorrowAirtimeData';
import UserQuickTopUp from '../UserQuickTopUp/UserQuickTopUp';
import Notifications from '../Notifications/Notifications';
import MobileSubscriberProfile from '../SubscriberProfile/MobileSubscriberProfile';
import { Outlet } from 'react-router-dom';
import SubscribersWallet from '../Wallet/wallet';

const Content = ({ activeComponent }) => {
  console.log('onMenuItemClick', activeComponent);
  const renderComponent = () => {
    switch (activeComponent) {
      case 'userProfile':
        return <MobileSubscriberProfile />;
      case 'myWishList':
        return <MyWishList />;
      case 'usageHistory':
        return <UsageHistory />;
      case 'borrowAirtime':
        return <BorrowAirtimeData />;
      case 'quickTopUp':
        return <UserQuickTopUp />;
      case 'manageSubscription':
        return <ManageSubscription />;
      case 'TransactionHistory':
        return <TransactionHistory />;
      case 'SubscriberOrders':
        return <SubscriberOrders />;
      case 'notifications':
        return <Notifications />;
        case 'wallet' :
          return <SubscribersWallet/>
      default:
        return <DefaultView />; // Default component (dashboard or any other default view)
    }
  };

  return (
    <div className="content">
      <ContentHeader />
     <Outlet/>
    </div>
  );
};

export default Content;
