import wtl from "../../images/vitelfeatures/wtllogo.png";
import ontrac from "../../images/ONTRAC LOGO.png";
import intellims from "../../images/INTEL LOGO.png";
import mtn from "../../images/MTN LOGO.png"

const Partners = (()=>{


    return(

        <>
      <section className=" section-default mt-none mb-none">
<div className="containe">
<h2 className="mb-sm">Our <strong>Partners</strong></h2>
<br/>
<strong>
<div className="row">
<div className="col-sm-6 col-md-4 col-lg-3">
<div className="square-holder">
<img className='appCardLogo ' src={ontrac} />{" "}
</div>
</div>
<div className="col-sm-6 col-md-4 col-lg-3">
<div className="square-holder">
<img className='appCardLogo' src={wtl} />{" "}
</div>
</div>
<div className="col-sm-6 col-md-4 col-lg-3">
<div className="square-holder">
<img className='appCardLogo' src={mtn} />{" "}
</div>
</div>
<div className="col-sm-6 col-md-4 col-lg-3">
<div className="square-holder">
<img className='appCardLogo' src={intellims} />{" "}
</div>
</div>


</div>
</strong>
</div>
</section>
  
        
        </>
    )
})

export default Partners