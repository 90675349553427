import Faq from "../faq"
import "./bottomMenu.css";

import wtl from "../../images/vitelfeatures/wtllogo.png";
import ontrac from "../../images/ONTRAC LOGO.png";
import intellims from "../../images/INTEL LOGO.png";
import mtn from "../../images/MTN LOGO.png"

import imageAbout from "../../images/aboutt.jpg"
import { useEffect } from "react";

const BottomMenu = (()=>{

  
   useEffect(() => {
     document.title =  `Vitel Wireless | About Vitel Wireless `
   }, []);

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);


    return (
      <>
        <section className='about-section'>
          <div className='con_side-about'>
            <div className='row displayRow'>
              <div className='content-column col-lg-6 col-md-12 col-sm-12 order-2'>
                <div className='inner-column'>
                  <div className='sec-title'>
                    <h2>The world (Nigeria) we Live-in Today</h2>
                    “The danger which is least expected soonest comes to us. At
                    the end of the day, the goals are simple: Safety and
                    security. The safety of our loved ones shall be our
                    priority.” “The automobiles, phones, guns, even domestic
                    staffs have all brought death, injury and the most
                    inestimable sorrow and deprivation to millions of us
                    (Nigerians).”
                    {/* <h2>Vitel Wireless! Everything Connected</h2> */}
                  </div>
                  <div className='btn-box'>
                    <a href='#' className='theme-btn btn-style-one'>
                      About Us
                    </a>
                  </div>
                  <ul className='ulSETlist'>
                    <h2>Vitel Wireless Limited</h2>
                    <li>
                      {" "}
                      Vitel Wireless Limited is a GSM 2nd Level operator in
                      Nigeria licensed by NCC to provide nationwide, every
                      aspect of network operators services, leveraging cell
                      towers of MTN. We are an innovative company that offers
                      cutting edge technology using mobile network to deliver
                      services.
                    </li>
                    <li>
                      The team have worked together as a unit since 2011. The
                      team have worked with all 4 MNOs in Nigeria/NCC and Law
                      Enforcement Agencies to define and support regulatory
                      issues. Current core management team includes,{" "}
                      <span style={{ fontWeight: "bold" }}>
                        Engr. E. Kenneth Nwabueze
                      </span>{" "}
                      ,{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        MFR (Executive Chairman), Engr.{" "}
                      </span>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Srikantha Kadur (Head of Technology){" "}
                      </span>{" "}
                      ,{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Engr. Simon Oyeke (CTO){" "}
                      </span>{" "}
                      ,
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Chudi Nwabueze (COO){" "}
                      </span>{" "}
                      ,
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Temitope Micheal (Head, Bus. Dev){" "}
                      </span>{" "}
                      ,
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Ngozi Mba (CMO, Media & Branding){" "}
                      </span>{" "}
                      ,
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Barr. Mfon Eno (Head, Legal & Regulatory)
                      </span>
                      , plus 5 others.
                    </li>
                  </ul>
                  {/* <ul className="ulSETlist">
            <h2>Our services</h2>
            <li> Blue is powering the humanistic Network: Competition requires 
                thought leadership and innovation that are outside the box. Bulk of our strategy is to focus on IoT, AI, and Data-driven solutions for various uses including agriculture, automotive, and safe cities;
                 data and video analytics for safety, including building out an integrated image repository for national security.    
             </li>
            
          </ul> */}
                </div>
              </div>

              <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column wow fadeInLeft'>
                  <figure className='image-1'>
                    <a
                      href='#'
                      className='lightbox-image'
                      data-fancybox='images'
                    >
                      <img
                        title='Rahul Kumar Yadav'
                        className='rahul_image'
                        src={imageAbout}
                      />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div className='sec-title'>
              <h2>Our services</h2>
              <ul className='ulSETlist'>
                <li className="listTap">
                  {" "}
                  Blue is powering the humanistic network with a focus on
                  thought leadership and innovative, out-of-the-box solutions.
                  Our strategy primarily focuses on IoT, AI, and data-driven
                  solutions for diverse uses such as agriculture, automotive,
                  and safe cities. We also emphasize data and video analytics
                  for safety, including developing an integrated image
                  repository for national security.
                </li>
              </ul>

              <h2> Partners: </h2>
              <ul className='ulSETlist'>
                <h3>You are the #1 partner we have</h3>
                <li  className="listTap">
                  {" "}
                  We remain appreciative of your commitment to national
                  development, and we know that our road to success will pass
                  through the walls of you, your team, and your organization.
                  Nothing may be more important to our success than our
                  continued association with you and your network of people, in
                  one way or another.
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* <Faq /> */}

        {/* <div className='appCardLogo-div'>
          <div>
            {" "}
            <img className='appCardLogo ' src={ontrac} />{" "}
          </div>
          <div>
            {" "}
            <img className='appCardLogo' src={wtl} />{" "}
          </div>
          <div>
            {" "}
            <img className='appCardLogo' src={mtn} />{" "}
          </div>
          <div>
            {" "}
            <img className='appCardLogo' src={intellims} />{" "}
          </div>
        </div> */}





        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );
})


export default BottomMenu