import { useNavigate } from "react-router-dom";
// import familyImage from "../../images/bannerBlack.png";
import "./familyBanner.css";
import familyImage from "../../images/new-family-full1.png"
import ipadImage from "../../images/new-family-ipad1.png";
import phoneImage from "../../images/new-family-mobile1.png"




const FamilySafetyBanner = (()=>{


    const navigate = useNavigate();

    const navigateFamilySafety = (()=>{
 
     navigate("/family-safety")
    })
  

    return(
       
       <>
     <div className="ban_sec"  >
		<div className="">
			<div className="ban_img">
	<img src={familyImage} alt="banner" border="0"/>
	<img className="ipadSEc" src={ipadImage} alt="banner" border="0"/>
	<img className="phoneSEc" src={phoneImage} alt="banner" border="0"/>
				<div className="ban_text">
				
					<a className="learDrop-set"  onClick={navigateFamilySafety}>Learn More</a>
				</div>
			</div>
		</div>
	</div>
 </>
    )
    
})

export default FamilySafetyBanner
