import React, {useEffect} from 'react'
import Hero from '../layouts/HeroSection/Hero';
import UserLineDetails from '../layouts/UserDetailsComponent/UserLineDetails';
import SubscriberUtilities from '../layouts/SubcribersUtilities/SubscriberUtilities';
import VitelFeatures from '../layouts/VitelFeatures/VitelFeatures';
import QuickLink from '../layouts/QuickLink/QuickLink';
import Footer from '../layouts/footer';
import Devices from '../layouts/DeviceSection/Devices';
import IoTDevices from '../layouts/IoTDeviceComponent/IoTDevices';
import VitelServicePlan from '../layouts/VitelServicePlan/VitelServicePlan';
import TopUp from '../layouts/TopUpComponent/TopUp';
import SocialMediaBundlesLink from '../layouts/SocialMediaBundles/SocialMediaBundlesLink';

// import "./homPage.css"
import { useNavigate } from 'react-router-dom';
import FamilySafetyBanner from './FamilySafetyBanner/familyBanner';
import VasSection from '../layouts/VasBannerSection/vasSection';
import VoiceDataSection from '../layouts/voiceAndDataSection/voiceDataSection';
import DownloadBanner from '../layouts/DownloadBanner/downloadBanner';

// import Hero from '../HeroSection/Hero';
// import SubscriberUtilities from '../AppContainerComponent/SubscriberUtilities';
// import VitelFeatures from '../AppContainerComponent/VitelFeatures/VitelFeatures';
// import QuickLink from '../AppContainerComponent/QuickLink/QuickLink';
// import UserLineDetails from '../AppContainerComponent/UserDetailsComponent/UserLineDetails';

const HomePage = () => {
   useEffect(() => {
     document.title = "Vitel Wireless Limited | Home";
   }, []); 
   
  


  return (
    <div>
      <Hero />
      <DownloadBanner/>
      <TopUp />
      <VoiceDataSection/>
      <br/>
       <FamilySafetyBanner/>
       <br/>
       <VasSection/>
       <Devices />
    
      {/* <UserLineDetails /> */}
      <VitelServicePlan />
  
      <SubscriberUtilities />
     
      <IoTDevices />

      
      <VitelFeatures />
     
    </div>
  );
}

export default HomePage