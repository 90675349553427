import Faq from "../faq";
import "./faqPage.css";




const FaqPage = (()=>{


    return(


        <>
      <div className="containfaq-titlePush">

      <Faq/>  
      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
        
          </div>  
     

        </>
    )
})

export default FaqPage