import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import HomeNavComponent from './home-nav-component';
import { useState } from 'react';
import Footer from './footer';
import LearnMoreNav from './learnmore-nav';

const Faq = () => {
  const [hideFaq, sethideFaq] = useState(false);

  const handleHideFaq = () => {
    sethideFaq(!hideFaq);
  };

  const [hide2, setHide2] = useState(false);

  const handlehide2 = () => {
    setHide2(!hide2);
  };

  const [hide3, setHide3] = useState(false);

  const handlehide3 = () => {
    setHide3(!hide3);
  };

  const [hide4, setHide4] = useState(false);

  const handlehide4 = () => {
    setHide4(!hide4);
  };
  const [hide5, setHide5] = useState(false);

  const handlehide5 = () => {
    setHide5(!hide5);
  };



  return (
    <>
      {/* <LearnMoreNav/>  */}

      <div className="contain containfaq-title">
        <h1>Frequently Asked Questions</h1>

        <div className="containFAQ">
          <div className="cardfaq">
            <div className="cardfaqli" onClick={handleHideFaq}>
              <div>
                <h4>How much do I pay to get Vitel Wireless? </h4>
              </div>
              {!hideFaq ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hideFaq && (
              <div className="vitelsAnswer">
                {' '}
                Vitel Wireless will be available soon, and the pricing details will be disclosed at that time.
              </div>
            )}
          </div>
          <hr />

      <div className="cardfaq">
            <div className="cardfaqli" onClick={handlehide2}>
              <div>
                <h4>How long does it take to get Vitel Wireless? </h4>
              </div>
              {!hide2 ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hide2 && (
              <div className="vitelsAnswer">
                {' '}
                Vitel Wireless will be available soon. Once you place an order, you can expect immediate delivery. It’s
                that easy, hassle-free and seamless.
              </div>
            )}
          </div>
          <hr />
          <div className="cardfaq">
            <div className="cardfaqli" onClick={handlehide3}>
              <div>
                <h4>Do I need special training to use Vitel Wireless? </h4>
              </div>
              {!hide3 ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hide3 && (
              <div className="vitelsAnswer">
                {' '}
                No training is required. Vitel wireless is just like any other network provider in Nigeria. We also
                offer a special family security system to ensure your family's safety and provide efficient data offers.
              </div>
            )}
          </div>
          <hr />


            <div className="cardfaq">
            <div className="cardfaqli" onClick={handlehide4}>
              <div>
                <h4>What is family safety? </h4>
              </div>
              {!hide4 ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hide4 && (
              <div className="vitelsAnswer">
                {' '}
                It is the most powerful tool for every Nigerian to help secure themselves and their loved ones. 
                Another good reason to get Vitel Wireless SIM card, maybe as your second line
              </div>
            )}
          </div>
          <hr />



          <div className="cardfaq">
            <div className="cardfaqli" onClick={handlehide5}>
              <div>
                <h4>How do I partner with Vitel Wireless? </h4>
              </div>
              {!hide5 ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hide5 && (
              <div className="vitelsAnswer">
                {' '}
               Vitel Wireless welcomes all the potential partners, with you, we can make it big.
               <ul>
                <li>Go to "Become a partner" </li>
                <li> Click "Register your interest" </li>
                <li>Fill the form and submit your request, is simple and easy</li>
               </ul>
              </div>
            )}
          </div>
          <hr />


          {/* <button className="custom-btn btn-6"><span>Read More</span></button> */}
        </div>
      </div>

      
     
    </>
  );
};

export default Faq;
