import "./voiceAndDataSection.css"; 
import dataimage from "../../images/VOICE-AND DATA-BANNER1.png"
import { useNavigate, useNavigation } from "react-router-dom";
import ipadImage from "../../images/VOICE-IPAD.png";
import phoneImage from "../../images/HOMEVOICE-PHONE.png"

const VoiceDataSection = ()=>{

const navigate = useNavigate();

const navigateVoiceData = ()=>{

	navigate("/data-plan")
}


    return(


        <>
        {/* <section> */}
        <div className="ban_sec"  >
		<div className="">
			<div className="ban_imgs">
	    <img src={dataimage} alt="banner" border="0"/>
		<img className="ipadSaveIMG"  src={ipadImage} alt="banner" border="0"/>
		<img className="phoneSaveIMG" src={phoneImage} alt="banner" border="0"/>
				<div className="ban_text">
				 <div className="voiceDiv-strong">VOICE AND DATA</div>
					 {/* <strong>
					 <span></span> 
					</strong> */}
					<p>All Voice and Data Packages <br/></p> 
					 <a  onClick={navigateVoiceData}>Get Started</a>
				</div>
			</div>
		</div>
	</div>    

        {/* </section> */}
      
        
        
        </>
    )
}

export default VoiceDataSection