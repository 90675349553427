import "./familySafety.css";
import familyIMAGE from "../../images/familycoming-removebg-preview.png"
import { FaArrowDown, FaArrowRight } from "react-icons/fa6";
import imghow1 from "../../images/works1.png";
import imghow2 from "../../images/works2.png";
import imghow3 from "../../images/works3.png";
import imgdown1 from "../../images/DOWN1.png"
import imgdown2 from "../../images/DOWN2.png"
import imgdown3 from "../../images/DOWN3.png";
import imagesaledevice from "../../images/INSIDE-PHONE.jpg"


import VitelFeatures from "../VitelFeatures/VitelFeatures";
import { useEffect } from "react";
import img4 from "../../images/secure-me-banner.png"

const FamilySafety = (() => {

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);


  return (
    <>
      <section className="banner">
        <div className="banner-Cover">
        <div className="bans-text">
          <div className="bans-textMain">
           <p className="everyTinP">Every </p>
           <span className="vtWillessM">Vitel Wireless <p className="simCARDD">Sim Card</p> </span>

           <span className="siComes">  Comes With This Family Safety Features Built In. </span>
          </div>
          <br/>
         
          <p className="most-powerfulText">It is the most 
            powerful tool for every Nigerian
          to help secure themselves and their loved ones. Another good reason to get Vitel Wireless SIM card, 
          maybe as your second line </p>
          <p className="another_text"></p>
        
         
          <br/>
         
               
               <br/>
            <p className="danger_text">“The danger which is least expected soonest comes to us. 
              At the end of the day, the goals are
              simple:
              <span> Safety and security. The safety of our loved ones shall be our priority.”
                “The automobiles, phones, guns, even domestic staffs have all brought death,</span>
                  injury and the most inestimable sorrow and deprivation to millions of Nigerians.”</p>
            <a href="#" className="link-2">   </a>
        </div>
        </div>

    </section>
       <section>
        <div className="howITworks_HEad">
          <h2 >HOW IT WORKS</h2>
          {/* <div className="proVide-line"></div> */}
        </div>


        <div className="allCoverhwstep row">
          <div className="setAllhwStep col-12 col-md-6 col-lg-4">
            <div className="hwStep">
              <p>Step 1  </p>
              {/* <i className="hdBig"><FaArrowDown  className="hdBig"/>  </i>  */}


            </div>
            <div className="hwStepBody">
              <img src={imghow1} />
              <img className="mobDown" src={imgdown1} />
              {/* <div className="iiIMgwork">
               </div> */}
              {/* <div className="iiIMgworkDiv">Purchase 
              <span style={{fontWeight:"bold"}}> Vitel Wireless SIM
              card</span> and use it as
              your primary or secondary
              phone number.</div>
              */}
            </div>
          </div>
          <div className=" setAllhwStep col-12 col-md-6 col-lg-4">
            <div className="hwStep">
              <p>Step 2   </p>
            </div>
            <div className="hwStepBody">
              <img src={imghow2} />
              <img className="mobDown" src={imgdown2} />
              {/* <div className="iiIMgwork">
               </div> */}
              {/* <div className="iiIMgworkDiv">Purchase <span style={{fontWeight:"bold"}}> Vitel Wireless SIM
              card</span> and use it as
              your primary or secondary
              phone number.</div>
              */}
            </div>
          </div>
          <div className=" setAllhwStep col-12 col-md-6 col-lg-4">
            <div className="hwStep">
              <p>Step 3</p>
            </div>
            <div className="hwStepBody3">
              <img src={imghow3} />
              <img className="mobDown" id="modown-last" src={imgdown3} />

              {/* <div className="iiIMgwork">
               
              </div> */}
              {/* <div className="iiIMgworkDiv">Purchase <span style={{fontWeight:"bold"}}> Vitel Wireless SIM
              card</span> and use it as
              your primary or secondary
              phone number.</div>
              */}
            </div>
          </div>
          </div>
       </section>

  
        <div class="background-container"> <span class="background-overlay"></span>
          <div class="background-cover containerPall">
            <div class="line-heading">
              <div class="line-heading-left">
                <h2>Extend your protection </h2>
                {/* <h4>Talk to us</h4> */}
              </div>
              <p>Information can also be shared with Police, DSS, personal security team, or vigilante group when necessary. </p>
            </div>
          </div>
        </div>
   
      <div className="setSalediv-card">
        <div className="setSalediv">
          <h2>Get discreet 24/7 protection with SecureMe App, and LBS enabled watches, safety jewelry, and accessories</h2>
          <p>
            Enjoy peace of mind with a personal safety app and SmartWear Security devices such as smart watches, safety keychains,
            and jewelries that offer discreet protection and access to emergency responders (112 contact centers) anytime, anywhere.
          </p>
          <h2>Personal security monitoring at affordable prices</h2>
          <ul>
            Starting at N7,500/month or N72,000/year when
            Your monitoring plan includes a free companion mobile app. You can see  that works when you order your safety devices
            <li>Initial broadcast of your SOS messages</li>
            <li>The app alerts up to 5 contacts and emergency responders of your LBS location</li>
            <li>Records and save your location every 5 minutes for up 4 hours</li>
            <li>Safety timer shares your location from 15 minutes to 4 hours with your contacts</li>
            <li>24/7 check-in calls or texts with Kosican Security Limited security monitoring agents 
              when you’re feeling insecure.</li>
          </ul>
        </div>
        <div className="setSalediv-image-div">
          <img className="setSalediv-image" src={imagesaledevice} />

        </div>

        <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      </div>
      
    <VitelFeatures />




      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />



    </>
  )
})
export default FamilySafety