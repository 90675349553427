import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import support from '../../images/supportimg.jpg';
import './SupportPage.css';
import Faq from '../faq';

const SupportPage = () => {
  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.title = 'Vitel Wireless | e-commerce';
    scrolltop();
  }, []);

  return (
    <div className="supportParentDiv">
      <div className='mainSupportdiv'>
        <div className="supportDiv">
          <div className="supportdivtext">
            <h2>NEED HELP ?</h2>
            <p>
              We’re a new model of gsm company, so it’s natural that you might have questions along the way. Best way to
              get help is to direct message us on{' '}
              <a className="supportwhatsappDiv" href='https://wa.me/message/H5AOTL5HAANZK1' target='blank'>WhatsAPP @ 07077011111 or 07120111111.</a> We are available 24/7
              to listen to your needs.{' '}
            </p>

            <p>
              You can drop a note via email at{' '}
              <span style={{ color: '#507AE6', fontWeight: 'bolder' }}>support.love@vitelwireless.com</span> or call us
              07120111112, 07120111113.{' '}
            </p>
          </div>

          <div className="supportimgDiv">
            <img src={support} alt="" />
          </div>
        </div>
      </div>

      <div className="parentsupportTrending">
        <h3>Trending issues and frequently asked questions.</h3>
        <div className="supportTrending">
          <div className="FaqDiv">
            <Faq />
          </div>

          <div className="FaqDivimgDiv">
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/faq-illustration-download-in-svg-png-gif-file-formats--customer-questions-and-answers-helpdesk-pack-network-communication-illustrations-3749051.png?f=webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
