import React, { useEffect, useState, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem('isLoggedIn')) || false
  );
  const userIsAuthenticated = JSON.parse(localStorage.getItem('subscriberInformation'));
  const timeoutRef = useRef(null); 
  const navigate = useNavigate();

  const INACTIVITY_TIME = 8 * 60 * 1000; 

  // Function to log out the user
  const logout = () => {
    console.log('User logged out due to inactivity');
    localStorage.setItem('isLoggedIn', JSON.stringify(false));
    localStorage.removeItem('subscriberInformation'); 
    navigate('/subscriber-login');
  };

  
  const resetTimer = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(logout, INACTIVITY_TIME);
  };

  // Track user activities and reset timer on any event
  const activityHandler = () => resetTimer();

  useEffect(() => {
    if (userIsAuthenticated) {
      // Add event listeners to detect user activity
      window.addEventListener('mousemove', activityHandler);
      window.addEventListener('keydown', activityHandler);
      window.addEventListener('click', activityHandler);

      // Set the initial inactivity timer
      resetTimer();
    }

    return () => {
      // Cleanup event listeners and timeout on unmount
      window.removeEventListener('mousemove', activityHandler);
      window.removeEventListener('keydown', activityHandler);
      window.removeEventListener('click', activityHandler);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [userIsAuthenticated]);

  if (!userIsAuthenticated) {
    // Redirect to login if the user is not authenticated
    return <Navigate to="/subscriber-login" />;
  }

  // If authenticated, render the protected route's children components
  return children;
};

export default ProtectedRoute;
