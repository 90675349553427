import "./shoppingCart.css";
import image from "../../images/wear/watch2.jpg";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  clearCartProduct,
  increase,
  removeProduct,
  decrease,
} from "../../redux/productCounter";
import { clear } from "@testing-library/user-event/dist/clear";
import cartImage from "../../images/SHOP.png";
import { PaystackButton } from "react-paystack";
import { FaHome } from "react-icons/fa";
import { IoIosReturnLeft } from "react-icons/io";
import SearchBar from "./searchBar";
import useFectch from "../../utility/getFunction";
import { orderLocationId, orderMenuId } from "../../utility/axios";

const ShoppingCart = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [referenceCode, setReferenceCode] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [checkQuantity, setCheckQuantity] = useState('');

  const dispatch = useDispatch();

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.title = 'Vitel Wireless | Shopping cart';
    scrolltop();
  }, []);

  const afterPaymentNavigateECommerce = () => {
    navigate('/subscriber-login');
  };
  const afterPaymentHome = () => {
    navigate('/');
  };
  const [vat, setVat] = useState(0);
  const navigate = useNavigate();

  const navigateEcommerce = () => {
    navigate('/e-commerce');
  };

  const { productItems } = useSelector(state => state.cartProduct);

  const totalPrice = productItems.reduce((acc, item) => {
    return acc + Number(item.pricePerItem) * item.quantity;
  }, 0);

  // const productItems1 = useSelector((state)=>state.cartProduct)

  console.log(' productItems>>>>>>', productItems);

  const defaultEmail = 'chuks1243@gmail.com';

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');
  //
  const navigateHome = () => {
    navigate('/');
  };

  const totalVat = productItems.reduce((acc, item) => {
    if (item.addVat === 'Yes') {
      return totalPrice * 0.075;
    }
  }, 0);

  useEffect(() => {
    setVat(totalVat);
  }, [totalVat]);

  const calculateVAT = (pricePerItem, quantity, addVat) => {
    if (addVat === 'Yes') {
      return (pricePerItem * quantity * 7.5) / 100;
    }
    return 0;
  };

  const calculateItemTotalPrice = (pricePerItem, quantity) => {
    return pricePerItem * quantity;
  };

  const calculateGrandTotal = () => {
    return productItems.reduce((total, item) => {
      return (
        total +
        calculateItemTotalPrice(item.pricePerItem, item.quantity) +
        calculateVAT(item.pricePerItem, item.quantity, item.addVat)
      );
    }, 0);
  };

  const handlePaystackSuccessAction = reference => {
    setReferenceCode(reference.reference);
    dispatch(clearCartProduct());
    setModalShow(true);
  };
  const handlePaystackCloseAction = () => {
    navigate('/shopping-cart');
  };

  const componentProps = {
    email: defaultEmail,
    amount: totalPrice * 100,

    // metadata: {

    //   fullname: firstName,
    //   phone: phoneNumber,
    //   additionNumber,
    //   deliveryAddress,
    //   addtionInfo,
    //   state,
    //   city
    // },

    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    text: 'PAY NOW',
    onSuccess: reference => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const totalNum = productItems.length;

  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));

  const handleProceedDelivery = () => {
    if (subscribersData) {
      navigate('/delivery-pickup');
    } else {
      navigate('/subscriber-login');
    }
  };

  const numChecker = parseInt(checkQuantity[0], 10);

  const {
    data: productsData,
    isPending: productsIsPending,
    error: productsError,
  } = useFectch(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`);

  const matchingProducts =
    productsData.products &&
    productsData.products.filter(product => {
      return productItems.some(image => image.comboId === product.comboId);
    });

  // const testNow = matchingProducts
  const isProductAvailable = matchingProducts && matchingProducts.every(product =>
    product.fields.every(field =>
      field.fieldItems.every(item => item.currentQuantity > 0)
    )
  );
  console.log("product>>>", matchingProducts)

  console.log("product-in-cart>>>", isProductAvailable)

  const handleCheckNumber = product => {
    const matchData = matchingProducts &&
    matchingProducts.filter(data => product.comboId === data.comboId);

    setCheckQuantity(matchData.map(item => item.fields[0].fieldItems[0]?.currentQuantity));
    dispatch(increase(product.productId));
  };

  const filterAvailableProduct = matchingProducts && matchingProducts.flatMap((pro)=>(
         pro.productDetailImages
  ))
  useEffect(() => {
 
  }, [checkQuantity]);



console.log("checkAvailable>>>>>>>",checkQuantity)
  return (
    <>
    <div className="yepPushSearch-ecom">
    <SearchBar />
    </div>
     

      <div className="bredMove">
        <nav className="breadcrumbs">
          <a className="breadcrumbs__item" onClick={navigateHome}>
            Home
          </a>
          <a className="breadcrumbs__item" onClick={navigateEcommerce}>
            All Products
          </a>
          <a className="breadcrumbs__item  is-active">Shopping Cart</a>

          {/* <a className="breadcrumbs__item ">Checkout</a>  */}
        </nav>
      </div>
      <div className=" container-sp">
        {productItems?.length === 0 ? (
          <div className=" emptyCartDIv">
            <div>
              {' '}
              <img src={cartImage} className="image-Empty" />{' '}
            </div>
            <h2>NO Product in Your Cart</h2>

            <button className="button-65" role="button" onClick={navigateEcommerce}>
              Go Shopping
            </button>

            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        ) : (
          <div className="wrapp wrapper-contenter animated fadeInRight">
            <div className="row">
              <div className="col-md-12 col-lg-9 inner-cartstyle">
                <div className="ibox">
                  <div className="ibox-title">
                    <span className="pull-right">
                      (<strong> {totalNum} </strong>) items
                    </span>
                    <h5>Items in your cart</h5>
                  </div>

      {
        productsIsPending && <div type="submit" className="yetLoasder" >
               Loading...
      </div>
       }

              {productItems && productItems.map((product) => (
                    <div className='ibox-content'>
                      <div className='table-responsive'>
                        <div className='shoping-cart-table'>
                          
                            <div className='tr-container-product'>
                              <div>
                                <img className="cart-product-imitation" src={product.displayImage} />
                              </div>
                              <div className='desc'>
                              
                                <div className="yenProduct">
                                <h4>{product.productName} </h4>
                                <div className="descriptioncover">

                                  <h5>Description </h5>
                                  <div className="spanLINE-cart"></div>
                                  <dd className="description-Text">{product.shortDescription}</dd>
                                </div>
                                <div className='price-back'>
                                  <span>
                                    Price:{' '}
                                    <span className="boldtesting">
                                      {' '}
                                      {symbol}
                                      {product?.pricePerItem && product?.pricePerItem.toLocaleString()}{' '}
                                    </span>
                                  </span>
                                </div>
                                </div>
                                <div className='controlcounter'>
                                  <button
                                    className="tractminus"
                                    onClick={() => dispatch(decrease(product.productId))}
                                    disabled={product.quantity === 1}
                                  >
                                    <i>
                                      <AiOutlineMinus size={30} />
                                    </i>
                                  </button>
                                  <div className="incrementnum">
                                    {' '}
                                    <span> {product.quantity}</span>
                                  </div>
                                  {}

                                  <button
                                    className="trackplus"
                                    onClick={() => handleCheckNumber(product)}
                                    disabled={
                                      product.categoryName === 'Premium/Value Added Services' ||
                                      numChecker <= product.quantity
                                    }
                                  >
                                    {' '}
                                    <i>
                                      <BsPlus size={30} />
                                    </i>
                                  </button>
                                </div>

                                <div className="AlertRed-quantity"></div>
                                <div className="m-t-sm removeDiv">
                                  <a className="text-muted" onClick={() => dispatch(removeProduct(product.productId))}>
                                    <i className="fa fa-trash trashChangeCol"></i> Remove{' '}
                                    <span className="item-itemRemove">item</span>
                                  </a>
                                </div>
                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                  ))}





                 <div className="ibox-content">
                    <button className="btn btn-white continuebtn-shopping" onClick={navigateEcommerce}>
                      <i className="fa fa-arrow-left"></i> Continue shopping
                    </button>
                    {/* <button
                      className='button-24'
                      role='button'
                      onClick={() => dispatch(clearCartProduct())}
                    >
                      Clear Cart
                    </button> */}
                  </div>


          {/* <table id="cart" className="table table-hover table-condensed">
    				<thead>
						<tr>
							<th style={{width:'50%'}}   >Product</th>
							<th style={{width:"10%"}}>Price</th>
							<th style={{width:"8%"}}>Quantity</th>
							<th style={{width:"22%"}} className="text-center">Subtotal</th>
							<th style={{width:"10%"}}></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td data-th="Product">
								<div className="row">
									<div className="col-sm-2 hidden-xs"><img src="http://placehold.it/100x100" alt="..." className="img-responsive"/></div>
									<div className="col-sm-10">
										<h4 className="nomargin">Product 1</h4>
										<p>Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet.</p>
									</div>
								</div>
							</td>
							<td data-th="Price">$1.99</td>
							<td data-th="Quantity">
								<input type="number" className="form-control text-center" value="1"/>
							</td>
							<td data-th="Subtotal" className="text-center">1.99</td>
							<td className="actions" data-th="">
								<button className="btn btn-info btn-sm"><i className="fa fa-refresh"></i></button>
								<button className="btn btn-danger btn-sm"><i className="fa fa-trash-o"></i></button>								
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr className="visible-xs">
							<td className="text-center"><strong>Total 1.99</strong></td>
						</tr>
					
					</tfoot>
				</table> */}


             
             
             </div>
              </div>
              <div className="col-md-3 safeJpp">
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>Cart Summary</h5>
                  </div>
                  <div className="ibox-conten-check">
                    <div className="toDivvats">
                      <div className="totalspan">Total Item:</div>
                      <div className="font-bold">
                        <span className="" style={{ fontSize: '1em' }}>
                          {symbol}
                          {totalPrice?.toLocaleString()}
                        </span>
                      </div>
                    </div>

                    <div className="toDivvats">
                      <div className="totalspan">Total Vat:</div>
                      <div className="font-bold">
                        <span className=""></span>

                        <span className="" style={{ fontSize: '1em' }}>
                          {symbol}
                          {vat &&
                            vat.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                      </div>
                    </div>

                    <div className="toDivvats">
                      <div className="totalspan">Total: </div>
                      <div className="font-bold">
                        <span className="" style={{ fontSize: '1em' }}>
                          {' '}
                          &#8358;{' '}
                          {parseInt(calculateGrandTotal()).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>

                    <hr />

                    <div className="m-t-sm">

                    {
                      !productsIsPending ?
                      
                      <button className="button-6" role="button" 
                      disabled={isProductAvailable === false} onClick={handleProceedDelivery}>
                        Proceed to Checkout
                      </button> : <button className="button-6" role="button" >
                      <span class="loaderButton" id='searcMob-btn'></span>{' '}
                      </button> 
                      }
                     {
                      isProductAvailable === false && 
                      <div style={{color:"black",justifyContent:"center",textAlign:"center" }}
                      className="notFond_outofStock">Product(s) out of stock</div>
                     }
                     
                     
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <label>Payment</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="setFOrm-modal">
          {/* <label>Email address</label>
        <input className="addRESs-input"/> */}
          Proceed to payment
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Pay Now
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: 'green' }}>
            Order Completed Succcesfully
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>
          <p>You have completed your order, you can purchase more durable and affordable products from us,</p>
          <p>Thanks for your patronage, your goods will be delivered soon!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={navigateEcommerce}>
            <IoIosReturnLeft /> Continue shopping
          </Button>
          <Button onClick={afterPaymentHome} className="aCartMpro">
            <FaHome /> Go Home
          </Button>
        </Modal.Footer>
      </Modal>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};
export default ShoppingCart;
