import React, { useEffect, useState, useRef } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import logo from '../../images/secondNewLogo.jpg';
import { useSelector } from 'react-redux';
import { vitelWirelessAxios } from '../../utility/axios';
import { SecondLoader, ButtonLoader } from '../../utility/Loader';

const Login = () => {
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(59);
  const [isActive, setIsActive] = useState(false);
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const [otpValues, setOtpValues] = useState(new Array(6).fill(''));
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showNetworkErrormsg, setShowNetworkErrormsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);

  const navigation = useNavigate();
  const { productItems } = useSelector(state => state.cartProduct);

  useEffect(() => {
    const container = document.getElementById('container');
    const registerBtn = document.getElementById('register');
    const loginBtn = document.getElementById('login');

    if (registerBtn && loginBtn && container) {
      const handleRegisterClick = () => container.classNameList?.add('active');
      const handleLoginClick = () => container.classNameList?.remove('active');

      registerBtn.addEventListener('click', handleRegisterClick);
      loginBtn.addEventListener('click', handleLoginClick);

      // Cleanup event listeners on component unmount
      return () => {
        registerBtn.removeEventListener('click', handleRegisterClick);
        loginBtn.removeEventListener('click', handleLoginClick);
      };
    }
  }, []);

  const handleSignInClick = e => {
    e.preventDefault();
    setIsActive(true);
    setIsOtpVisible(true);
  };

  const handleVerifyClick = () => {
    checkOtp();
    // navigation('/delivery-pickup');
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!/^[0-9]$/.test(value)) {
      e.target.value = '';
      return;
    }

    // Update the OTP values array
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (index < otpRefs.length - 1 && value) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      otpRefs[index - 1].current.focus();
    }
  };

  const defaultOtp = '654321';
  const checkOtp = async () => {
    setShowLoader(true);
    const enteredOtp = otpValues.join('');
    const formattedMSISDN = formik.values.MSISDN.replace(/^0/, '234');
    if (enteredOtp === defaultOtp) {
      const userMSISDN = {
        MSISDN: formattedMSISDN,
        otp: enteredOtp,
        checkdefaultOtp: true,
      };
      // console.log(userMSISDN);
      await vitelWirelessAxios
        .post(`/subscribers/verify-login-otp`, userMSISDN)
        .then(res => {
          if (res.data.success === true) {
            setShowLoader(false);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('subscriberInformation', JSON.stringify(res.data.customerDetails));
            localStorage.setItem('MSISDN', formattedMSISDN);

            if (productItems.length > 0) {
              navigation('/delivery-pickup');
            } else {
              navigation('/customer/account/subscriber');
            }
          } else {
            setShowErrorMessage(true);
            setErrorMessage(res.data.message);
            // console.log(res.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setShowErrorMessage(true);
      // setErrorMessage("error!! invalid otp666");
      const userMSISDN = {
        MSISDN: formattedMSISDN,
        otp: enteredOtp,
        checkdefaultOtp: false,
      };
      console.log(userMSISDN);
      await vitelWirelessAxios
        .post(`/subscribers/verify-login-otp`, userMSISDN)
        .then(res => {
          // console.log('respond', res);
          // localStorage.setItem('isLoggedIn', 'true');
          if (res.data.success === true) {
             setShowLoader(false);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('subscriberInformation', JSON.stringify(res.data.customerDetails));
            localStorage.setItem('MSISDN', formattedMSISDN);
            // console.log('subscriberDetails', subscriberDetails);
            if (productItems.length > 0) {
              navigation('/delivery-pickup');
            } else {
              navigation('/customer/account');
            }
          } else {
            setShowErrorMessage(true);
            setErrorMessage(res.data.message);
            // console.log(res.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

    // if (enteredOtp === defaultOtp) {
    //   localStorage.setItem('isLoggedIn', 'true');
    //   // localStorage.setItem('subscriberInformation', 'true');
    //   console.log('subscriberDetails', subscriberDetails);
    //   if (productItems.length > 0) {
    //     // navigation('/delivery-pickup');
    //   } else {
    //     // navigation('/customer/account');
    //   }
    //   // console.log('ProductsCart', productItems);
    // } else {
    //   alert('OTP is incorrect');
    // }
  };

  const resendOTP = async () => {
    const formattedMSISDN = formik.values.MSISDN.replace(/^0/, '234');
    const MSISDN = {
      MSISDN: formattedMSISDN,
    };

    // console.log('resend otp check', MSISDN);
    await vitelWirelessAxios
      .post('/subscribers/login', MSISDN)
      .then(res => {
        console.log('res', res);
        if (res.data.success === true) {
          setIsActive(true);
          setIsOtpVisible(true);
        
        } else {
          console.log('cant login');
        }
      })
      .catch(err => console.log(' Login unsuccessfull', err));
    setShowErrorMessage(false);
    setMinutes(3);
    setSeconds(59);
  };

  useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, seconds, minutes]);

  const formik = useFormik({
    initialValues: {
      MSISDN: '',
    },
    validationSchema: Yup.object({
      MSISDN: Yup.string()
        .matches(/^[0-9]+$/, 'MSISDN must contain only numbers')
        .required('MSISDN is required'),
    }),

    onSubmit: async values => {
      setShowButtonLoader(true);
      const formattedMSISDN = values.MSISDN.replace(/^0/, '234');

      const MSISDN = {
        MSISDN: formattedMSISDN,
      };
      await vitelWirelessAxios
        .post('/subscribers/login', MSISDN)
        .then(res => {
          // console.log('res', res);
          if (res.data.success === true) {
            setIsActive(true);
            setIsOtpVisible(true);
            setShowButtonLoader(false);
          } else {
            setShowErrorMessage(true);
            setTimeout(() => {
              setShowButtonLoader(false);
            }, 10000);
            console.log('cant login');
          }
        })
        .catch(err => {
          setShowNetworkErrormsg(true);
        });

      // setLoader(false)
    },
  });
  return (
    <div className="parentLoginContainer">
      {showLoader ? (
        <SecondLoader />
      ) : (
        <div className="container" id="container">
          <div className="form-container sign-up">
            <form>
              <h1>Create Account</h1>
              <div className="social-icons">
                <a href="#" className="icon">
                  <i className="fa-brands fa-google-plus-g"></i>
                </a>
                <a href="#" className="icon">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="#" className="icon">
                  <i className="fa-brands fa-github"></i>
                </a>
                <a href="#" className="icon">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
              <span>or use your email for registeration</span>
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
              <input type="password" placeholder="Password" />
              <button>Sign Up</button>
            </form>
          </div>
          <div className="form-container sign-in">
            <div className="parentSignInDiv">
              {!isOtpVisible ? (
                <>
                  <h1>Sign In</h1>
                  <div className="social-icons">
                    <span>Enter Your Vitel Number, an OTP will Be sent there</span>
                  </div>
                  {showErrorMessage && <span style={{ color: 'red' }}>Incorrect MSISDN, please check the MSISDN </span>}
                  {showNetworkErrormsg && (
                    <span style={{ color: 'red' }}>opps!! Network Error, please try again later</span>
                  )}
                </>
              ) : (
                <>
                  <h1>OTP Verification</h1>
                  <div className="social-icons">
                    <span>Code has been sent to {formik.values.MSISDN}</span>
                  </div>
                </>
              )}
              {showErrorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
              {!isOtpVisible ? (
                <div className="parentinputForm">
                  <form onSubmit={formik.handleSubmit}>
                    <input
                      value={formik.values.MSISDN}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="MSISDN"
                      id="MSISDN"
                      placeholder="Enter vitel number"
                      className="emailInput"
                    />
                    {formik.touched.MSISDN && formik.errors.MSISDN ? (
                      <p className="error">{formik.errors.MSISDN}</p>
                    ) : null}
                    {showButtonLoader ? (
                      <button className="signInBtn">
                        <ButtonLoader />
                      </button>
                    ) : (
                      <button className="signInBtn" type="submit">
                        Sign In
                      </button>
                    )}
                  </form>
                </div>
              ) : (
                <>
                  <div className="otp-input-card2">
                    {/* <input className='otpinput' type="email" placeholder="Enter vitel number" /> */}
                    {otpRefs.map((ref, index) => (
                      <input
                        key={index}
                        type="text"
                        className="otpinput"
                        // maxLength="1"
                        ref={ref}
                        onChange={e => handleOtpChange(e, index)}
                        onKeyDown={e => handleOtpKeyDown(e, index)}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <div className="countdown-text">
                    Time Remaining: {''}
                    <span style={{ fontWeight: 600 }}>
                      {minutes < 10 ? `0${minutes}` : minutes} {': '}
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </div>
                  <span>
                    Didn't get the otp?{' '}
                    <button
                      className="resendOtpBtn"
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                      }}
                      onClick={resendOTP}
                    >
                      Resend otp
                    </button>
                  </span>
                  <button
                    className={seconds === 0 || minutes === 0 ? 'verifyBtn333Disable' : 'verifyBtn333'}
                    type="button"
                    disabled={seconds === 0 || minutes === 0}
                    onClick={handleVerifyClick}
                  >
                    Verify
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="toggle-container">
            <div className="toggle">
              <div className="toggle-panel toggle-left">
                <div className="loginLogo">
                  <img src={logo} alt="" />
                </div>
                <p>Enter your personal details to use all of site features</p>
                <button className="hidden" id="login">
                  Sign In
                </button>
              </div>
              <div className="toggle-panel toggle-right">
                <div className="loginLogo">
                  <img src={logo} alt="" />
                </div>
                <p className="toggle-panel-para">Login with your Vitel Number to use all of the site features</p>
                <div className="buttonDiv">
                  {/* <button className='hidden' id='register'>
                Sign Up
              </button> */}
                  <Link to="/">
                    <span className="homebtn" id="register">
                      Back To Home
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
