import "./downloadBanner.css"
import googleImage from "../../images/downloadVITel-google-removebg-preview.png";
import appleImage from "../../images/downloadVitel-apple-removebg-preview.png"

const DownloadBanner = (()=>{

    return(


        <>
       <div className='downloadBage'>
          <div className='downBackdownDiv'>
            
            <div  className='downBackdown'>Download Vitel Wireless App & Enjoy Exclusive offers</div> 
            <div className='downloadSet-btns'>
            <span> <img className='tenSIze1' src={googleImage} /> </span>
            <span><img className='tenSIze1' src={appleImage} />   </span>

          </div>
          </div>

          

        </div> 
        
        </>
    )
})
export default DownloadBanner