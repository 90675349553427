import { FaPlus } from 'react-icons/fa';
import './deliveryPage.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useEffect, useRef, useState } from 'react';
import useFectch from '../../utility/getFunction';
import { useForm } from 'react-hook-form';

import { Tabs, Placeholder } from 'rsuite';
import { FaRegSquare, FaImage, FaRegCircle } from 'react-icons/fa';
import { COLOR } from 'rsuite/esm/internals/constants';
import { CiDeliveryTruck } from 'react-icons/ci';
import { GiCardPickup } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearCartProduct } from '../../redux/productCounter';
import { PaystackButton } from 'react-paystack';
import {
  openTableRoomId,
  orderLocationId,
  orderLocationIdGen,
  orderMenuId,
  orderMenuIdGen,
  registerId,
  serviceStaffId,
  vitelWirelessAxios,
} from '../../utility/axios';
import { Alert } from 'react-bootstrap';
import { IoCheckmarkDone } from 'react-icons/io5';
import axios from 'axios';

let renderCount = 0;

const DeliveryPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm();
  renderCount++;

  const [displayForm, setDisplayForm] = useState(false);
  const [displayBtn, setDisplayBtn] = useState(true);
  const [state, setState] = useState('');
  const [localGvt, setLocalGvt] = useState([]);
  const [lgaId, setLgaId] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [total, setTotal] = useState('');
  const [delivery, setDelivery] = useState('Yes');
  const [isPending, setIsPending] = useState(false);
  const [pendindAddress, setPendingAdress] = useState(false);
  const [addressReturnData, setAddressReturnData] = useState('');
  const [addressAlertShow, setAddressAlertShow] = useState('');
  const [allAddressData, setAllAddressData] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [productLoad, setProductLoad] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [defaultAdd, setDefaultAdd] = useState('');
  const [tabValue, setTabValue] = useState('');
  const [selectedOptionAddress, setSelectedOptionAddress] = useState('');
  const [addressData, setAddressData] = useState('');
  const [pendingOrder, setPendingOrder] = useState(false);
  const [isChecked, setIschecked] = useState('false');
  const [pickupName, setPickupName] = useState('');

  const [selectedTab, setSelectedTab] = useState(1);
  const [returnOderCheck, setReturnOrderCheck] = useState('');
  const [alertDismis, setAlertDismis] = useState(false);
  const [showAddressPending, setShowAddressPending] = useState(true);
  const [sendAddress, setSendAddress] = useState('');
  const [selectDeliveryFee, setSelectDeliveryFee] = useState('');
  const [quantitySend, setQuantitySend] = useState("");
  const [feess, setFeess] = useState(0);

  // Default to '1' (Delivery)

  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));

  const loginEmail = subscribersData?.emailAddress;

  const myVariable = useRef();

  const handleDisplayForm = () => {
    setDisplayForm(!displayForm);
    setDisplayBtn(false);
  };
  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');
  //

  const { productItems } = useSelector(state => state.cartProduct);

  const totalPrice =
    productItems &&
    productItems?.reduce((acc, item) => {
      return acc + Number(item.pricePerItem) * item.quantity;
    }, 0);
  const count = productItems.length;

  const getOrderType = tabValue => {
    return tabValue === 1 ? 'delivery' : 'pickup';
  };
  const orderType = getOrderType(selectedTab);

  console.log('Submitting order type:', orderType);

  const formatedAmont = totalPrice.toLocaleString();

  const { data: states, isPending: statesIsPending, error: statesError } = useFectch('generals/states');
  const {
    data: localGvtCouncil,
    isPending: localGvtIsPending,
    error: localGvtError,
  } = useFectch('generals/local_govt');

  const { data: area, isPending: areaIsPending, error: areaError } = useFectch(`generals/getPickupCenter/${lgaId}`);

  const dispatch = useDispatch();
  const [vat, setVat] = useState(0);
  // const onChangeHanlerPickup = (e) => {

  //   console.log("e.target.value.area>>>>>", e.target.value);
  // }

  

  const onChangeHandler = e => {
    console.log('email', e.target.value);
    const newStateId = e.target.value;
    setState(newStateId);

    const filterLga = localGvtCouncil?.filter(lga => lga.stateId === newStateId);
    setLocalGvt(filterLga);
  };

  const handleLocalGvt = e => {
    const lgaIdGet = e.target.value;
    // console.log("lgaIdGet to send === >>>>>", lgaIdGet)
    setLgaId(lgaIdGet);
  };

  const handleSelectCenter = event => {
    const selectedCenter = event.target.value;
    setPickupName(selectedCenter);
  };

  const calculateVAT = (pricePerItem, quantity, addVat) => {
    if (addVat === 'Yes') {
      return (pricePerItem * quantity * 7.5) / 100;
    }
    return 0;
  };

  // const vatForEach = ()=>{
  //   return productItems.reduce((item) =>{
  //     return(
  //       calculateVAT(item.pricePerItem, item.quantity, item.addVat)
  //     )
  //   } )
  // }


  const calculateItemTotalPrice = (pricePerItem, quantity) => {
    return pricePerItem * quantity;
  };

  const calculateGrandTotal = () => {
    return (
      productItems &&
      productItems.reduce((total, item) => {
        return (
          total +
          calculateItemTotalPrice(item.pricePerItem, item.quantity) +
          calculateVAT(item.pricePerItem, item.quantity, item.addVat) +
          selectDeliveryFee
        );
      }, 0)
    );
  };



  // console.log("vatForEach>>>>", itemVat)

  const calculateItemLineTotal = (pricePerItem, quantity) => {
    return (
      productItems &&
      productItems.reduce((total, item) => {
        return calculateItemTotalPrice(item.pricePerItem, item.quantity);
      }, 0)
    );
  };
  const lineTotal = calculateItemLineTotal();

  const totalLineItemPrice =
    productItems && productItems.map(product => parseInt(product.pricePerItem) * product.quantity);

  console.log('lineTotal>>>>>>', totalLineItemPrice[0]);

  const totalVat = productItems.reduce((acc, item) => {
    if (item.addVat === 'Yes') {
      return totalPrice * 0.075;
    }
  }, 0);

  useEffect(() => {
    setVat(totalVat);
  }, [totalVat]);

  const handleCheckboxChange = event => {
    setIschecked(event.target.checked.toString());
  };
  useEffect(() => {
    setTotal(calculateGrandTotal());
  }, []);

  const navigate = useNavigate();
  const navigateEcommerce = () => {
    navigate('/e-commerce');
  };

  const navigateCart = () => {
    navigate('/shopping-cart');
  };
  const handlePaystackCloseAction = () => {
    //  navigate("/shopping-cart")
  };

  const afterPaymentNavigateECommerce = () => {
    navigate('/purchase-confirmation');
  };

  const handlePaystackSuccessAction = reference => {
    setReferenceCode(reference.reference);
    handleSubmitOrder();

    // afterPaymentNavigateECommerce()
  };
  const afterPaymentHome = () => {
    navigate('/');
  };

  const comboIdFilter = productItems && productItems.map(combo => combo.comboId);
  // console.log(' combo.comboId', comboIdFilter);

  const {
    data: productsData,
    isPending: productsIsPending,
    error: productsError,
  } = useFectch(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`);

  // console.log('checkIddddddddddddd>>>>>>', orderMenuId);

  const filteredComboData =
    productsData.products && productsData.products.includes(data => comboIdFilter === data.comboId);
  console.log(' comboIdFilter', filteredComboData);

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigateHome = () => {
    navigate('/');
  };

  const totalSendAmount = +calculateGrandTotal();

  console.log('totalSendAmount>>>>>', totalSendAmount);

  const componentProps = {
    email: loginEmail,
    amount: totalSendAmount * 100,

    // metadata: {

    //   fullname: firstName,
    //   phone: phoneNumber,
    //   additionNumber,
    //   deliveryAddress,
    //   addtionInfo,
    //   state,
    //   city
    // },

    publicKey: process.env.REACT_APP_PAYSTACK_KEY_ECOMMERCE,
    text: 'Confirm Order',
    onSuccess: reference => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const fullAdData = allAddressData.data;

  const defaultAddress = fullAdData && fullAdData.find(address => address.defaultAddress === 'true');

  const noDefaultSelected = fullAdData && fullAdData.slice(0, 1).map(data => data);

  // Function to handle View More/Show Less
  const handleToggle = () => {
    setShowAll(prev => !prev);
  };

  useEffect(() => {
    setSendAddress(noDefaultSelected?.length > 0 ? noDefaultSelected[0] : {});
  }, [sendAddress]);

  const numberAddress = fullAdData?.length;

  const handleAddressData = address => {
    setAddressData(address);
  };

  const selectedFieldItem = [];

  const itemsAll =
    productItems &&
    productItems.map(
      element =>
        element.fields &&
        element.fields.map(
          element => element.fieldItems && element.fieldItems.map(field => selectedFieldItem.push(field))
        )
    );

  const matchingProducts =
    productsData.products &&
    productsData.products.filter(product => {
      return productItems.some(image => image.comboId === product.comboId);
    });

  // useEffect(() => {

  //   if (tabValue == 2) {
  //     setDelivery("pickup")
  //   } else if (tabValue == 1) {
  //     setDelivery("delivery")
  //   } else {
  //     setDelivery("delivery")
  //   }

  // }, [delivery])

  const userData = JSON.parse(localStorage.getItem('subscriberInformation'));

  const customerId = {
    customerId: userData?.customerId,
  };
  const getVitelIoTProduct = async () => {
    const addressDetails = await vitelWirelessAxios.post('subscribers/getSubscriberAddress', customerId);
    setAllAddressData(addressDetails);
    if (addressDetails) {
      setShowAddressPending(false);
    } else {
      setShowAddressPending(true);
    }
  };

  const checkAddressLength = allAddressData?.data;

  useEffect(() => {
    const deliveryFees = 2000;
    setFeess(deliveryFees);

    if (orderType) {
      const addressToSend = addressData || defaultAddress || sendAddress;
      const pickupData = {
        state: state,
        lga: lgaId,
        area: pickupName,
        customerId: subscribersData?.customerId,
        firstName: subscribersData?.firstName,
        lastName: subscribersData?.lastName,
        emailAddress: subscribersData?.emailAddress,
        phoneNumber: subscribersData?.phoneNumber,
        additionalInformation: '',
        deliveryAddress: '',
        defaultAddress: '',
        alternativeNumber: '',
        serviceType: orderType,
      };

      setSelectDeliveryFee(orderType === 'delivery' ? deliveryFees : 0);
      setSelectedOptionAddress(orderType === 'delivery' ? addressToSend : pickupData);
    }
  }, [orderType, addressData, defaultAddress, pickupName, state, lgaId, sendAddress]);

  useEffect(()=>{

  },[quantitySend])
  

  console.log("result>>>>>>>>>>>matchingProducts",matchingProducts);  

  const handleSubmitOrder = async data => {
    setPendingOrder(true);


    const selectedQty = productItems &&
  productItems.flatMap(item =>
    matchingProducts.flatMap(product =>
      product.fields.flatMap(field =>
        field.fieldItems.map(fieldItem => ({
          fieldItemId: fieldItem?.fieldItemId,
          productId: fieldItem?.productId,
          productName: fieldItem?.productName,
          adjustmentPrice: 0,
          quantity: item.quantity,  // Use the quantity from productItems
          active: '1',
          currentQuantity: fieldItem.currentQuantity,
          productLocation: 'currentLocation',
          selected: true,
        }))
      )
    )
  );


const uniqueQty = selectedQty.reduce((acc, item) => {
  // Check if the product already exists in the accumulator
  const exists = acc.find(
    accItem => accItem.fieldItemId === item.fieldItemId
  );

  // If it doesn't exist, add it to the accumulator
  if (!exists) {
    acc.push(item);
  }

  return acc;
}, []);

const findMainQuantity = productItems && productItems.map(item => {
  // Find the corresponding product in uniqueQty based on fieldItemId or productId
  const product = uniqueQty.find(prod => prod.productName
    == item.productName
  );
  
  if (product) {
    // Return the matched product with the updated quantity
    return {
      fieldItemId: product?.fieldItemId,
      productId: product?.productId,
      productName: product?.productName,
      adjustmentPrice: 0,
      quantity: item.quantity,  // Use the quantity from productItems
      active: '1',
      currentQuantity: product.currentQuantity,
      productLocation: 'currentLocation',
      selected: true,
    };
  }
  return null;
}).filter(Boolean); // Filter out null values in case no match is found
 

//    const selectedQty = productItems &&
//    productItems.flatMap(item =>
//      matchingProducts.flatMap(product =>
//        product.fields.flatMap(field =>
//          field.fieldItems.map(fieldItem => ({
//            fieldItemId: fieldItem?.fieldItemId,
//            productId: fieldItem?.productId,
//            productName: fieldItem?.productName,
//            adjustmentPrice: 0,
//            quantity: item.quantity,  
//            active: '1',
//            currentQuantity: fieldItem.currentQuantity,
//            productLocation: 'currentLocation',
//            selected: true,
//          })))
//      )
//    );

//  const summedQty = selectedQty.reduce((acc, item) => {
//    if (acc[item.productId]) {
  
//      acc[item.productId].quantity += item.quantity;
//    } else {
    
//      acc[item.productId] = { ...item };
//    }
//    return acc;
//  }, {});
//  const result = Object.values(summedQty);

console.log("find>>>>>>>>>>>", uniqueQty);

console.log("result>>>>>>>>>>>", productItems); 

    
    // Convert the accumulator back to an array if needed
    // const result = Object.values(summedFieldItems);

      // const selectedQtySend = Object.values(selectedQty.reduce((acc, item) => {
      //   if (!acc[item.productId]) {
      //     acc[item.productId] = { ...item };
      //   } else {
      //     acc[item.productId].quantity += item.quantity;
      //   }
      //   return acc;
      // }, {}));


      // const summedData = selectedQty && selectedQty.reduce((acc, item) => {
      //   // Check if productId exists in accumulator
      //   if (acc[item.productId]) {
      //     acc[item.productId].quantity += item.quantity;  // Sum the quantity
      //   } else {
      //     acc[item.productId] = { ...item };  // Add the first instance of the product
      //   }
      //   return acc;
      // }, {});


const mainData = {
      serviceStaff: serviceStaffId,
      typeOfService: orderType,
      menuId: orderMenuId,
      openedTableRoomId: openTableRoomId,
      registerId: registerId,
      locationId: orderLocationId,
      hotelId: productsData.hotelId,
      sourceType: 'web',
      orderAddress: selectedOptionAddress,
      selectedProductQty: findMainQuantity,
       products: findMainQuantity && findMainQuantity.flatMap( qty =>
        matchingProducts &&
        matchingProducts.map(item => ({
          comboId: item.comboId,
          comboName: item.comboName,
          comboType: item.comboType,
          hotelId: item.hotelId,
          menuId: item.menuId,
          comboTypeCategory: item.comboTypeCategory,
          description: item.description,
          picture: item.picture,
          subCategoryName: item.subCategoryName,
          comboPrice: item.comboPrice,
          price: item.price,
          fields:
            item.fields &&
            item.fields.map(field => ({
              fieldId: field.fieldId,
              fieldName: field.fieldName,
              defaultCheckBox: field.defaultCheckBox,
              fieldType: field.fieldType,
              fulfillmentType: field.fulfillmentType,
              itemRequired: field.itemRequired,
              orderNumber: field.orderNumber,
              printOnInvoice: field.printOnInvoice,
              fieldItems:
                field.fieldItems &&
                field.fieldItems.map(fieldItem => ({
                  fieldItemId: fieldItem?.fieldItemId,
                  productName: fieldItem.productName,
                  quantity: fieldItem.quantity,
                  adjustmentPrice: fieldItem.adjustmentPrice,
                  productId: fieldItem.productId,
                  productLocation: fieldItem.productLocation,
                  currentQuantity: fieldItem.currentQuantity, 
                })),
            })),
          singleCombo: true,
          quantity: 0,
          comboTax: item.price * qty.quantity * 0.075,
          lineTotal: item.price * qty.quantity,
        }))),
      totalAmount: +totalSendAmount,
      hasTaxLevies: '',
      totalTax: vat,
      taxLevies: [],
      serviceWaiter: '',
      createdDateTime: '',
      orderDeliveryFee: +feess,
    };

    console.log('finalSubmissionTest>>>>',  mainData);

    mainData.products.forEach((e, i) => {
      const [qtyProd] = productItems.filter(itm => itm.comboId === e.comboId);
      e.quantity = qtyProd.quantity || 0;
      console.log('qtyProd>>>>>>>>>>', qtyProd.quantity);
      setQuantitySend(qtyProd.quantity)
    });

    try {
      const res = await vitelWirelessAxios.post('/fulfilments/orderRequest', mainData, {
        headers: {
          'x-access-token': 'fb-app01',
        },
      });
      if (res.data.success) {
        console.log('sucessData ==>>> ', res.data);
        setPendingOrder(false);
        setReturnOrderCheck(res.data);
        navigate(`/purchase-confirmation/${res.data.orderId}`, {
          state: { returnOderCheck: res.data },
        });
        dispatch(clearCartProduct());
      } else {
        setAlertDismis(true);
        setTimeout(() => {
          setAlertDismis(false);
        }, 4000);
      }
    } catch (err) {
      console.log('err', err);
      setPendingOrder(false);
    }
  };

  const handleSubmitAddress = async data => {
    setPendingAdress(true);
    const AddressData = {
      ...data,
      state: state,
      defaultAddress: data.defaultAddress.toString(),
    };
    // console.log('main>>>>>', AddressData);
    await vitelWirelessAxios
      .post('/subscribers/createSubscriberAddress', AddressData)
      .then(res => {
        console.log('sucessData', res.data);
        setAddressReturnData(res.data);
        setPendingAdress(false);
        setDisplayForm(false);
        setAddressAlertShow(true);
        // localStorage.setItem("CustomerId", JSON.stringify(res.data));
        scrolltop();
        setTimeout(() => {
          setAddressAlertShow(false);
        }, 4000);
        getVitelIoTProduct();
      })
      .catch(err => {
        console.log('err', err);
        setPendingAdress(false);
      });
  };

  useEffect(
    () => {
      getVitelIoTProduct();
    },
    [
      // fullAdData
    ]
  );
  // const handleOtionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // }

  return (
    <>
      <div className="generalShowDelivery">
        <div className="lay">
          <div className="bredMove myBreadMove">
            <nav className="breadcrumbs">
              <a className="breadcrumbs__item" onClick={navigateHome}>
                Home
              </a>
              <a className="breadcrumbs__item" onClick={navigateEcommerce}>
                All Products
              </a>
              <a className="breadcrumbs__item " onClick={navigateCart}>
                Shopping Cart
              </a>
              <a className="breadcrumbs__item  is-active">Delivery-pickup</a>
            </nav>
          </div>

          <h4 className="p-1">
            Your order will be delivered to the address below. Would you like self pick up? Choose pick up center
            nearest to you
          </h4>
          <hr />
          {alertDismis && <div className="warnPlace">Something went wrong while placing order, try again later!</div>}

          {/* <input type="radio"/> */}

          <Tabs activeKey={selectedTab} onSelect={value => setSelectedTab(value)}>
            <Tabs.Tab
              eventKey={1}
              title={<span style={{ fontWeight: 'bold', fontSize: '20px' }}>DELIVERY</span>}
              icon={<CiDeliveryTruck size={25} />}
            >
              <div className="page-contentsDIVS">
                <h4 className="aDDresBok">ADDRESS BOOK</h4>

                <div className="page-content-All">
                  <div className="page-conte">
                    <div></div>
                    {fullAdData?.length >= 1 ? (
                      <div className="addRESBoxffr">
                        {showAddressPending && (
                          <div>
                            <i className=""></i>
                            <span className="loader"></span>
                          </div>
                        )}

                        {defaultAddress ? (
                          <div className="addRESBox ">
                            <div>
                              <input
                                type="radio"
                                id="option1"
                                name="options"
                                checked
                                className="chDesign"
                                value={defaultAddress}
                              />
                            </div>
                            <div>
                              <h4>
                                {defaultAddress?.firstName} {defaultAddress?.lastName}{' '}
                              </h4>
                              <div className="nameNowSwt">{defaultAddress?.deliveryAddress}</div>
                              <div className="nameNowSwt">{defaultAddress?.phoneNumber}</div>

                              <div className="yepDefault">DEFAULT ADDRESS</div>
                            </div>
                          </div>
                        ) : (
                          <div className="addRESBox">
                            <div>
                              <input
                                type="radio"
                                id="option1"
                                name="options"
                                checked
                                className="chDesign"
                                value={sendAddress}
                              />
                            </div>
                            <div>
                              <h4>
                                {sendAddress?.firstName} {sendAddress?.lastName}{' '}
                              </h4>
                              <div className="nameNowSwt">{sendAddress?.deliveryAddress}</div>
                              <div className="nameNowSwt">{sendAddress?.phoneNumber}</div>
                            </div>
                          </div>
                        )}
                        {showAll &&
                          fullAdData &&
                          fullAdData
                            .filter(address => address.defaultAddress !== 'true')
                            .map(address => (
                              <div className="addRESBox " key={address.addressId}>
                                <div>
                                  <input
                                    type="radio"
                                    id="option1"
                                    name="options"
                                    className="chDesign"
                                    onClick={() => handleAddressData(address)}
                                  />
                                </div>
                                <div>
                                  <h4>
                                    {address.firstName} {address.lastName}{' '}
                                  </h4>
                                  <div className="nameNowSwt">{address.deliveryAddress}</div>
                                  <div className="nameNowSwt">{address.phoneNumber}</div>

                                  <p>{address.additionalInformation}</p>
                                  {/* <span className='yepDefault'>DEFAULT ADDRESS</span> */}
                                </div>
                              </div>
                            ))}

                        {fullAdData?.length >= 2 ? (
                          <button onClick={() => handleToggle()} className="yebViewMore">
                            {showAll && showAll ? 'Show Less' : 'View More'}
                          </button>
                        ) : null}
                      </div>
                    ) : (
                      <div className="textONdevl">
                        {' '}
                        Your address will appear here, you can add multiple address, depending on your location
                        <p className="notetextONdevl">Note: Select your prefered address before proceed to payment</p>
                      </div>
                    )}
                    <div></div>
                  </div>
                  {addressAlertShow && (
                    <Alert variant="success">
                      <i>
                        <IoCheckmarkDone size={25} />
                      </i>
                      <span className="wtAddedSucess p-2">Address has been added to your address book</span>
                    </Alert>
                  )}
                  <div></div>

                  <div className="add-newAdress" onClick={handleDisplayForm}>
                    {' '}
                    <i>
                      <FaPlus />
                    </i>{' '}
                    ADD ADDRESS{' '}
                  </div>

                  {displayForm && (
                    <Form
                      className="form-checkSS"
                      onSubmit={handleSubmit((data, event) => {
                        // event.target.reset()
                        console.log('Address>>>>>>', data);
                        handleSubmitAddress(data);
                      })}
                    >
                      <div className="addANewA">Add a new address</div>
                      <hr />
                      <Row className="mb-3 divPushChange">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>
                            First Name <span className="red-text">*</span>{' '}
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter first name"
                            defaultValue={userData?.firstName}
                            {...register('firstName', {
                              required: 'First name is required',
                            })}
                          />
                          <span className="cum-error">{errors.firstName?.message}</span>
                        </Form.Group>

                        <Form.Group className="mb-3 d-none" controlId="formGridAddress2">
                          <Form.Control
                            placeholder="Apartment, land mark, studio, or floor"
                            {...register('customerType')}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 d-none" controlId="formGridAddress2">
                          <Form.Control
                            placeholder="Apartment, land mark, studio, or floor"
                            value={userData?.customerId}
                            {...register('customerId')}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 d-none" controlId="formGridAddress2">
                          <Form.Control
                            value={orderType}
                            placeholder="Apartment, land mark, studio, or floor"
                            {...register('addressType')}
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>
                            Last Name <span className="red-text">*</span>
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter last name"
                            defaultValue={userData?.lastName}
                            {...register('lastName', {
                              required: 'Last name  is required',
                            })}
                          />
                          <span className="cum-error">{errors.lastName?.message}</span>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3 divPushChange">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>
                            Phone Number <span className="red-text">*</span>
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter phone number"
                            defaultValue={subscribersData?.phoneNumber}
                            {...register('phoneNumber', {
                              required: 'Phone number is required',
                              // maxLength: {
                              //   value: 11,
                              //   message:
                              //     'Phone Number must not be more than eleven digit'
                              // }
                            })}
                          />
                          <span className="cum-error">{errors.phone?.message}</span>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>Additional Phone Number</Form.Label>
                          <Form.Control
                            placeholder="Enter Your Additional Phone Number"
                            {...register('alternativeNumber')}
                          />
                        </Form.Group>
                      </Row>
                      <Form.Group className="mb-3" controlId="formGridAddress2">
                        <Form.Label>
                          Email <span className="red-text">*</span>
                        </Form.Label>
                        <Form.Control
                          placeholder="Email address"
                          defaultValue={subscribersData?.emailAddress}
                          {...register('emailAddress', {
                            required: 'Email address is required',
                            // maxLength: {
                            //   value: 11,
                            //   message:
                            //     'Phone Number must not be more than eleven digit'
                            // }
                          })}
                        />
                        <span className="cum-error">{errors?.emailAddress?.message}</span>
                      </Form.Group>

                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridState">
                          <Form.Label>
                            State <span className="red-text">*</span>
                          </Form.Label>
                          <Form.Select defaultValue="Choose..." onChange={onChangeHandler}>
                            <option disabled>Choose...</option>
                            {states && states.map(state => <option value={state.stateId}> {state.name} </option>)}

                            {statesIsPending && <option>Loading...</option>}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                          <Form.Label>
                            L.G.A <span className="red-text">*</span>
                          </Form.Label>
                          <Form.Select defaultValue="Choose..." {...register('lga')}>
                            <option disabled>Choose...</option>
                            {localGvt &&
                              localGvt.map((lga, i) => (
                                <option key={i.id} value={lga.lgaId} name="townOfResidence">
                                  {' '}
                                  {lga.name}
                                </option>
                              ))}
                            {localGvtIsPending && <option>Loading...</option>}
                          </Form.Select>
                        </Form.Group>
                      </Row>

                      <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label>
                          Delivery Address <span className="red-text">*</span>
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter delivery address..."
                          {...register('deliveryAddress', {
                            required: 'Delivery address  is required',
                            // maxLength: {
                            //   value: 11,
                            //   message:
                            //     'Phone Number must not be more than eleven digit'
                            // }
                          })}
                        />
                        <span className="cum-error">{errors.address?.message}</span>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formGridAddress2">
                        <Form.Label>Additional information</Form.Label>
                        <Form.Control
                          placeholder="Apartment, land mark, studio, or floor"
                          {...register('additionalInformation')}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" id="formGridCheckbox">
                        <Form.Check
                          // checked={isChecked === "true"}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          label="Set as Default Address"
                          {...register('defaultAddress')}
                        />
                      </Form.Group>

                      {pendindAddress ? (
                        <Button className="button-COnfirmAdress">
                          <i className=""></i>
                          <span class="loaderButton"></span>{' '}
                        </Button>
                      ) : (
                        <Button className="button-COnfirmAdress" type="submit">
                          Submit Address{' '}
                        </Button>
                      )}
                    </Form>
                  )}
                </div>
              </div>

              {/* <Placeholder.Paragraph graph="image" /> */}
            </Tabs.Tab>

            <Tabs.Tab
              eventKey={2}
              title={<span style={{ fontWeight: 'bold', fontSize: '20px' }}>PICK UP</span>}
              // onClick={() => setDelivery("No")}
              icon={<GiCardPickup size={25} />}
            >
              <h4 className="aDDresBok">CHOOSE PICKUP LOCATION NEAREST TO YOU</h4>
              <div className="setDivided">
                <div className="setDivPayInput">
                  <label className="labelText">
                    Select State <span className="red-text">*</span>{' '}
                  </label>
                  <select className=" selectmain classic22" onChange={onChangeHandler}>
                    <option disabled>Choose...</option>
                    {states && states.map((state, index) => <option key={index} value={state.stateId}> {state.name} </option>)}

                    {statesIsPending && <option>Loading...</option>}
                  </select>
                  <br />
                </div>

                <div className="setDivPayInput">
                  <label className="labelText">Local Government </label>
                  <select className=" selectmain  classic22" onChange={handleLocalGvt}>
                    <option disabled>Choose...</option>
                    {localGvt &&
                      localGvt.map((lga, index) => (
                        <option key={index} value={lga.lgaId} name="townOfResidence">
                          {' '}
                          {lga.name}
                        </option>
                      ))}
                    {localGvtIsPending && <option>Loading...</option>}
                  </select>
                  <br />
                </div>

                <div className="setDivPayInput">
                  <label className="labelText">Select Area </label>

                  <select className="selectmain classic22" onChange={handleSelectCenter}>
                    <option value="" >please select</option>
                    {area &&
                      area.map(a => (
                        <option key={a.pickupArea} value={a.pickupArea}>
                          {a.pickupArea}
                        </option>
                      ))}
                    {area.length === 0 && !areaIsPending && <option disabled>No available center here yet</option>}
                  </select>

                  <br />
                </div>
              </div>

              {/* <Placeholder.Paragraph graph="square" /> */}
            </Tabs.Tab>
          </Tabs>
        </div>

        <div className="secondDiv-deliv">
          <div className="orDERsum">Order Summary</div>
          <hr />
          <div className="shAddedAddres">
            <div>item's total({count})</div>
            <div style={{ fontSize: '1.3em' }}>
              {symbol}
              {formatedAmont}
            </div>
          </div>

          <div className="shAddedAddres">
            <div>Vat total</div>
            <div style={{ fontSize: '1.3em' }}>
              <span className="" style={{ fontSize: '1em' }}>
                {symbol}
                {vat &&
                  vat?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </span>
            </div>
          </div>

          <div className="shAddedAddres">
            <div>Delivery Fee</div>
            <div style={{ fontSize: '1.3em' }}>
              {symbol} {selectDeliveryFee}
            </div>
          </div>

          {/* <div className="shAddedAddres">
            <div> Sub total</div>
            <div>{symbol}1000000</div>
          </div> */}
          <div className="shAddedAddres">
            <div>Total</div>
            <div>
              <span className="" style={{ fontSize: '1.3em' }}>
                {' '}
                &#8358;{' '}
                {parseInt(calculateGrandTotal()).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
          <div className="button-COnfirm-btn">
            {pendingOrder ? (
              <button className="button-COnfirm">
                <i className=""></i>
                <span class="loaderButton"></span>{' '}
              </button>
            ) : (
              <PaystackButton
                disabled={total === 0 ? true : false || (checkAddressLength?.length === 0 && pickupName?.length === 0)}
                {...componentProps}
                className="button-COnfirm"
              />
            )}

            {/* <button className="button-COnfirm" role="button" onClick={handleSubmitOrder}> TEst </button> */}
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default DeliveryPage;
