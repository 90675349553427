import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { vitelWirelessAxios } from '../../utility/axios';
import axios from 'axios';
import './FindStore.css';
import { FaMagnifyingGlassLocation, FaEyeSlash, FaEye } from 'react-icons/fa6';
import useFetch from '../../utility/getFunction';
import { useForm } from 'react-hook-form';

const FindStore = () => {
  const [state, setState] = useState(0);
  const [lga, setLGA] = useState('');
  const [allData, setAllData] = useState([]);
  const [city, setCity] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [mapLocation, setMapLocation] = useState(null); // To store map latitude and longitude
  const [allvitelState, setAllVitelState] = useState([]);
  const [allvitelLGA, setAllVitelLGA] = useState([]);
  const [filteredLGAs, setFilteredLGAs] = useState([]);
  const [lgaId, setLgaId] = useState("");
  const [pendingSearch, setPendingSearch] = useState(false);
  const [pendingSearchState, setPendingSearchState] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [noStateData, setNoStateData] = useState(false)
  const [noStateSelect, setNoStateSelect] = useState("");
  const [logitudeField, setLogitudeField] = useState("");
  const [latitudeField, setLatitudeField] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: area, isPending: areaIsPending } = useFetch(`partners/getLocationCities/${lgaId}`);

  useEffect(() => {
    const fetchStatesAndLGAs = async () => {
      try {
        const stateResponse = await vitelWirelessAxios.get('generals/states');
        const lgaResponse = await vitelWirelessAxios.get('generals/local_govt');
        setAllVitelState(stateResponse.data);
        setAllVitelLGA(lgaResponse.data);
      } catch (error) {
        console.error('Error fetching states and LGAs:', error);
      }
    };
    fetchStatesAndLGAs();
  }, []);

  const handleFilterLGA = (stateId) => {
    const stateLGA = allvitelLGA.filter((lga) => lga.stateId === stateId);
    setFilteredLGAs(stateLGA);
    setState(stateId);
  };

  const handleViewMap = (latitude, longitude) => {
      setShowMap(true);
      setLogitudeField(longitude)
     setLatitudeField(latitude);
  };




  const handleShowAddress = (() => {

    const targetElement = document.getElementById('premiumSection');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  })

  const handleHideViewMap = () => {
    setShowMap(false);
    setMapLocation(null);
  };

  const handleLocationSearch = async data => {
    setSearchText(false)
     setPendingSearch(true);
    const searchData = {
      locationName: data.locationName,
    };
    console.log('main>>>>>send', searchData);
    await vitelWirelessAxios
      .post('/partners/locationStoreSearch',searchData)
      .then(res => {
        console.log('sucessData', res);
        setAllData(res.data);
        if (res.data.length != 0) {
          handleShowAddress()
        }else{
          setSearchText(true)
        }
        setPendingSearch(false);
       })
      .catch(err => {
        console.log('err', err);
        setPendingSearch(false);
      });
  };


  const handleCheckSearch = (() => {
    console.log("see result", state)
    if (state === 0) {
      setNoStateSelect(true)
    } else {
      handleLocationSearch2()
    }
   
  })

  const handleLocationSearch2 = async data => {
    setNoStateData(false)
    setNoStateSelect(false)
    setPendingSearchState(true);
    const searchDataState = {
      stateId: state,
      lgaId: lgaId,
      cityId: city

    };
    // console.log('main>>>>>', searchData);
    await vitelWirelessAxios
      .post('/partners/locationStoreSearch', searchDataState)
      .then(res => {
        console.log('sucessData', res.data);
        setAllData(res.data)
        setPendingSearchState(false);
        if (res.data.length != 0) {
          handleShowAddress()
        }else{
          setNoStateData(true)
        }

      })
      .catch(err => {
        console.log('err', err);
        setPendingSearchState(false);
      });
  };

  // const mapNow = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCHuzJphZLHbAkH60YvSMelhKInZ-fizHk&q=${latitudeField},${logitudeField}`

 const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434508616!2d${logitudeField}!3d${latitudeField}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f20!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577f6827b1b57bd!2sVictoria%20State%20Library!5e0!3m2!1sen!2sus!4v1601300546184!5m2!1sen!2sus`;

  return (
    <div className="findstoreparentdiv">
      <div className="findstoreHeaderparentdiv">
        <h2 className="finstoreText">
          Find Vitel Wireless Store <FaMagnifyingGlassLocation className="findIcon" /> near your Location
        </h2>

        <form className="firstInputSec" onSubmit={handleSubmit(handleLocationSearch)}>
          <input
            type="text"
            className="firstInputSec-input"
            placeholder="Enter Location, store name or area"
            {...register('locationName', { required: 'Please enter location name, store name or area' })}
          />
          {
            pendingSearch ? <button className="setBtnLocation" >
            
            <span class="loaderButton" id='searcMob-btn'></span>{' '}
          </button> :  <button type="submit" className="setBtnLocation">Search <span className='hideLOcat'>location</span> </button>
          }
         
        </form>
        <span className="cum-error ErrorShow">{errors.locationName?.message}</span>
        {searchText && <div style={{color:"black", fontWeight:"bold"}}>No result found</div>}

        <Formik
          initialValues={{ state: '', lga: '', city: '' }}
          onSubmit={() => handleLocationSearch2()}
        >
          {({ setFieldValue }) => (
            <Form className="findstoreparentFormdiv">
              <div className="findStoreFieldOrDiv"><h4>OR</h4></div>
              <div className="selectStoretitle cdtitle"><h4>Filter store location by: </h4></div>

              <div className="selectstorecity">
                <select
                  name="state"
                  onChange={(e) => {
                    const stateId = e.target.value;
                    setFieldValue('state', stateId);
                    handleFilterLGA(stateId);
                  }}
                >
                  <option value="">Select State</option>
                  {allvitelState.map((state) => (
                    <option key={state.stateId} value={state.stateId}>{state.name}</option>
                  ))}
                </select>

                <select name="lga" onChange={(e) => setLgaId(e.target.value)}>
                  <option>Select LGA</option>
                  {filteredLGAs.map((lga) => (
                    <option key={lga.lgaId} value={lga.lgaId}>{lga.name}</option>
                  ))}
                </select>

                <select name="city" onChange={(e) => setCity(e.target.value)}>
                  <option>Select City</option>
                  {area && area.map((city) => (
                    <option key={city.cityId} value={city.cityId}>{city.cityName}</option>
                  ))}
                  {area?.length === 0 && !areaIsPending && <option disabled>No store location here yet</option>}
                </select>
                       {
                         pendingSearchState ? <button className="setBtnLocation">
                        <i className=""></i>
                        <span class="loaderButton"></span>{' '}
                      </button> : <a onClick={handleCheckSearch} 
                       className="setBtnLocation">Filter location</a>
                       }
                
              </div>
              {noStateSelect && <p className="cum-error">Please select a State</p>}
              
              {noStateData && <div style={{color:"black", fontWeight:"bold"}}>No result found</div>}
            </Form>
          )}
        </Formik>
      </div>

      <div className="locationListParent">
        <div className="locationList">
          <div className="findStoresectionHeader">
            <h3>Vitel Wireless store Location Near You</h3>
            <p>Meeting our customers where they are</p>
          </div>

          {showMap && (
              <div className="hideMapDiv">
                <button onClick={handleHideViewMap}>
                  Hide map view <FaEyeSlash className="viewIcon" />
                </button>
              </div>
            )}

            {!showMap ? (
              <div className="locationtable">
                <table className="tableInerSet tableMAP-Set" id='premiumSection'>
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Store Name</th>
                      <th scope="col">Store Type</th>
                      <th scope="col">State Name</th>
                      <th scope="col">LGA </th>
                      <th scope="col">City</th>
                      <th scope="col">Address</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {
                      allData && allData.map((data, i) => (
                        <tr>
  

                          <th scope="row">{i + 1}</th>
                          <td data-label="Store Name">{data.storeName}</td>
                          <td data-label="Stor Type">{data.storeType}</td>
                          <td data-label="State Name">{data.stateName}</td>
                          <td data-label="LGA"> {data.lgaName} </td>
                          <td data-label="City">  
                            {data.cityName}
                          </td>
                          <td data-label="Address">{data.address}</td>
                         <td >
                            <span className="viewMapbtn" onClick={()=> handleViewMap(data.latitude, data.longitude)}>
                            View  Map <FaEye className="viewIcon" />
                            </span>
                          </td>
                        </tr>
                      ))
                    }

                  </tbody>
                </table>

               

              </div>
            ) : (
              <div className="mapContainer">
                <iframe
                  title="Google Map"
                 src={mapSrc}
                  width="100%"
                  height="80%"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            )}


        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default FindStore;